<template>
    <div class="start-box">
        <ul >
            <li v-for="(c,index) of startList" :key="index" :class="{checked:startList[index].bOn}" @click="changeList(index)">
                {{c.titleName}}
                <i class="el-icon-star-off"/>
                {{c.name}}
            </li>    
        </ul>
        <el-form ref="form" :model="form" :rules="rules" class="form">
            <el-form-item prop="min">
                <el-input @blur="priceBlur" prefix-icon="el-icon-location-outline" placeholder="0" v-model="form.min"  />
            </el-form-item>
            <span>~</span>
            <el-form-item prop="max">
                <el-input @blur="priceBlur"   prefix-icon="el-icon-location-outline" placeholder="1500" v-model="form.max" />
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
const MIN_NUMBER = 1;
const MAX_NUMBER = 100000;
export default {
  props: {
      
  },
  data() {
    return {
      form: { min: "", max: "" },
      startList: [
        { titleName: "≤2",name: "经济", value: 2, bOn: false, fitlerTypeByOne:'start'},
        { titleName: "3", name: "舒适", value: 3, bOn: false, fitlerTypeByOne:'start'},
        { titleName: "4", name: "高档", value: 4, bOn: false ,fitlerTypeByOne:'start'},
        { titleName: "5", name: "豪华", value: 5, bOn: false ,fitlerTypeByOne:'start' }        
      ],
      arr: [], //筛选之后的数组,
      rules: {
        min: [
        //   { required: true, message: "必填项，请维护", trigger: "blur" },
        //   { validator: this.validateCom, trigger: "blur" },
          { validator: this.validateMin, trigger: "blur" }
        ],
        max: [
        //   { required: true, message: "必填项", trigger: "blur" },
        //   { validator: this.validateCom, trigger: "blur" },
          { validator: this.validateMax, trigger: "blur" }
        ]
      }
    };
  },
  computed:{
        ...mapState({
            htCnParams:state =>state.htCn.htCnParams,
            htCnSonTags:state =>state.htCn.htCnSonTags,
        }),
  },
  watch:{
        startList:{
            handler(){   //在父组件进行操作更新dom，子组件更新数据
            let filterList=this.startList.filter(item => item.bOn == true); //已经获取到数据 
            this.SET_HTCN_SONNTAGS({start:filterList})
            this.SET_HTCN_PARAMS({starRate:this.htCnSonTags.start.map(e=>e.value).join(",")})
            this.$emit('changeList')
            },
             deep:true,
            // immediate: true
        },
        htCnSonTags:{
            handler(val){    
                // this.startList.forEach((item,index)=>{ // 使用watch会陷入死循环 （此方案废弃）
                //     val.start.forEach((e,i)=>{
                //         if(item.name==e.name){
                //              this.startList[index].bOn=true
                //             
                //         }else{
                //             this.startList[index].bOn=false
                //            
                //         }
                //     })
                // }) 
                if(val.start.length==0){ // 当全部清除的时候
                    this.startList.forEach(item=>{
                        item.bOn=false
                    })
                }        
            },
            deep:true,
            // immediate: true
        }
  },
  methods: {
        ...mapMutations({
            SET_HTCN_PARAMS:'htCn/SET_HTCN_PARAMS', // 设置查询参数
            SET_HTCN_SONNTAGS:'htCn/SET_HTCN_SONNTAGS'
        }),
    priceBlur(){
        this.$refs.form.validate((valid)=>{
          if(valid){
              let obj={}
              obj.name=`${this.form.min}-${this.form.max}`;
              obj.mix=this.form.min;
              obj.max=this.form.max;
              obj.fitlerTypeByOne='input'
            if(this.form.min && this.form.max){
            this.SET_HTCN_SONNTAGS({input:[obj]})
            this.SET_HTCN_PARAMS({priceRange:this.htCnSonTags.input[0].name})
             this.$emit("getInputObj", obj);
            }else{
                this.SET_HTCN_PARAMS({priceRange:''})
                this.SET_HTCN_SONNTAGS({input:[]})
                this.$emit("getInputObj", obj);
            }
          }else{
            return ;
          }
        })
    },
    handlerRemove(){
        console.log(this.startList,"this.startList")
        // 这里清除要把startList遍历全部改变为false
        this.startList.forEach(item=>{
            item.bOn=false
        })
    },
    removeAll(){
            this.form.min=''
            this.form.max=''
            this.startList.forEach(item=>{
            item.bOn=false
        })
        this.$emit('removeAll')
    },
    changeList(i) {
        this.startList[i].bOn = !this.startList[i].bOn;
        let filterList=this.startList.filter(item => item.bOn == true); //已经获取到数据 
        this.SET_HTCN_SONNTAGS({start:filterList})
        this.SET_HTCN_PARAMS({starRate:this.htCnSonTags.start.map(e=>e.value).join(",")})
        this.$emit('changeList')
    },
    // validateCom(rule, value, callback) {
    //   const one = Number(value);
    //   if (Number.isInteger(one)) {
    //     if (one < MIN_NUMBER) {
    //       return callback(new Error(this.$message.error('输入值必须大于0')));
    //     } else if (one > MAX_NUMBER) {
    //       return callback(new Error(this.$message.error('输入值必须小于100000')) );
    //     }
    //     return callback();
    //   }
    //   return callback(new Error(this.$message.error('输入值必须为正整数')) );
    // },
    validateMin(rule, value, callback) {
      const one = Number(value);
      const max = Number(this.form.max);
      if (!max || one <= max) {
        return callback();
      }
      return callback(this.$message.error("输入值不得大于最大阈值"));
    },
    validateMax(rule, value, callback) {
      const one = Number(value);
      const min = Number(this.form.min);
      if (!min || one >= min) {
        return callback();
      }
      return callback(this.$message.error("输入值不得小于最小阈值"));
    }
  }
};
</script>

<style scoped lang="scss">
.el-input {
  width: 140px;
}
.start-box {
  display: flex;
  align-items: center;
  .form {
    display: flex;
    height: 40px;
    margin-left: 20px;
    span {
      line-height: 40px;
    }
  }
  ul {
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  li {
    width: 111px;
    height: 40px;
    //   display: inline-block;
    cursor: pointer;
    margin-left: 5px;
    font-size: 16px;
    padding: 10px;
    background: #f5f5fa;
    box-sizing: border-box;
    margin-right: 10px;
  }
  .box-i {
    width: 0;

    height: 0;

    border-bottom: 100px solid red;

    border-left: 100px solid transparent;
  }
  li:hover {
    //   background-color: #409eff;
    //   color: #fff;
    //   border: 1px #fff solid;
    //   border: 1px solid #409eff;
  }
  li.checked {
    //   border: 1px #fff solid;
    border: 1px solid #409eff;
  }
}
/deep/ .el-input--small .el-input__inner {
  height: 40px;
}
</style>
